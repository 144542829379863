// client/src/contexts/TokenContext.js

import React, { useState, createContext } from "react";

export const TokenContext = createContext();

export const TokenProvider = props => {
  const [tokenDetails, setTokenDetails] = useState({
    step: 1,
    tokenName: "",
    tokenSymbol: "",
    decimals: 18,
    initialSupply: 0,
    burnable: false,
    prelaunchPause: false,
  });
  const [isChatMode, setChatMode] = useState(false);

  const next = () => setTokenDetails(prevDetails => ({ ...prevDetails, step: prevDetails.step + 1 }));
  const prev = () => setTokenDetails(prevDetails => ({ ...prevDetails, step: prevDetails.step - 1 }));
  const goToStep = (step) => setTokenDetails(prevDetails => ({ ...prevDetails, step }));

  const updateTokenDetails = (newDetails) => {
    setTokenDetails(prevDetails => ({
      ...prevDetails,
      tokenName: newDetails.tokenName !== "unknown" ? newDetails.tokenName : prevDetails.tokenName,
      tokenSymbol: newDetails.tokenSymbol !== "unknown" ? newDetails.tokenSymbol : prevDetails.tokenSymbol,
      decimals: newDetails.decimals !== "unknown" ? parseInt(newDetails.decimals) : prevDetails.decimals,
      initialSupply: newDetails.initialSupply !== "unknown" ? parseFloat(newDetails.initialSupply) : prevDetails.initialSupply,
      burnable: newDetails.burnable !== "unknown" ? newDetails.burnable : prevDetails.burnable,
      prelaunchPause: newDetails.prelaunchPause !== "unknown" ? newDetails.prelaunchPause : prevDetails.prelaunchPause,
    }));
  }

  return (
    <TokenContext.Provider value={{ next, prev, goToStep, tokenDetails, setTokenDetails, updateTokenDetails, isChatMode, setChatMode}}>
      {props.children}
    </TokenContext.Provider>
  );
};

export default TokenProvider;
