import React, { useState, useContext, useMemo, useEffect, useRef, lazy, Suspense } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AuthContext } from '../../auth/AuthContext';
import { TokenContext } from '../../contexts/TokenContext';
import BillIntro from '../BillIntro/BillIntro';

import styles from './HomePageStyles.module.css';

const Bill = lazy(() => import('../Bill/Bill'));
const BasicTokenDetails = lazy(() => import('../BasicTokenDetails/BasicTokenDetails'));
const TokenOptionsForm = lazy(() => import('../TokenOptionsForm/TokenOptionsForm'));
const TokenReview = lazy(() => import('../TokenReview/TokenReview'));
const CompilationProgress = lazy(() => import('../CompilationProgress/CompilationProgress'));
const ConnectWalletStep = lazy(() => import('../ConnectWalletStep/ConnectWalletStep'));
const ContractDeploymentStep = lazy(() => import('../ContractDeploymentStep/ContractDeploymentStep'));
const DeploymentSuccess = lazy(() => import('../DeploymentSuccess/DeploymentSuccess'));
const ChatBot = lazy(() => import('../ChatBot/ChatBot'));
const SVGAnimation = lazy(() => import('../SVGAnimation/SVGAnimation.js'));

const PUBLIC_URL = process.env.PUBLIC_URL;
const BACKGROUND_IMAGE = `${PUBLIC_URL}/factoryImage.svg`;

const components = {
  2: BasicTokenDetails,
  3: TokenOptionsForm,
  4: TokenReview,
  5: CompilationProgress,
  6: ConnectWalletStep,
  7: ContractDeploymentStep,
  8: DeploymentSuccess
};

const HomePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { tokenDetails, isChatMode, setChatMode } = useContext(TokenContext);
  const { initializeWeb3Details } = useContext(AuthContext);
  const svgRef = useRef(null);
  const [isFactorySvgLoaded, setFactorySvgLoaded] = useState(false);


  const renderComponent = useMemo(() => {
    if (isChatMode) {
      return <ChatBot />;
    }
    const Component = components[tokenDetails.step];
    return Component ? <Component initializeWeb3Details={initializeWeb3Details} /> : <BillIntro />;
  }, [tokenDetails, initializeWeb3Details, isChatMode]);

  useEffect(() => {
    import('../BasicTokenDetails/BasicTokenDetails');
    import('../TokenOptionsForm/TokenOptionsForm');
    import('../TokenReview/TokenReview');
    import('../CompilationProgress/CompilationProgress');
    import('../ConnectWalletStep/ConnectWalletStep');
    import('../ContractDeploymentStep/ContractDeploymentStep');
    import('../DeploymentSuccess/DeploymentSuccess');
  }, []);

  useEffect(() => {
    if (svgRef.current) {
      svgRef.current.addEventListener('load', function() {
        setFactorySvgLoaded(true);
      });
    }
  }, []);


  return (
    <div className={styles.root}>
      <object
        ref={svgRef}
        id="factory-svg"
        type="image/svg+xml"
        data={BACKGROUND_IMAGE}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: -1,
          bottom: 0
        }}
      />
      <Suspense fallback={<div>Loading SVG Animation...</div>}>
        <SVGAnimation svgRef={svgRef}/>
      </Suspense>
      <div className={`${styles.flexContainer}`}>
        <div className={` ${isMobile ? styles.columnMobile : styles.column}`}>
          <Suspense fallback={<div>Loading Component...</div>}>
            {renderComponent}
          </Suspense>
        </div>
        {!isMobile && isFactorySvgLoaded && (
        <div className={`${styles.column} ${styles.flexEnd}`}>
          <Suspense fallback={<div>Loading Bill...</div>}>
              <Bill setChatMode={setChatMode} />
          </Suspense>
        </div>
      )}
      </div>
    </div>
  );
}

export default React.memo(HomePage);