// client/src/auth/useAuth.js

import { useDispatch, useSelector } from 'react-redux';
import useAuth0Auth from "./useAuth0Auth";
import useMetamaskAuth from "./useMetamaskAuth";
import { authSuccess, authError } from '../reducers/authReducer';

const useAuth = (authType = 'auth0') => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const userId = useSelector(state => state.auth.userId);

  const auth0Auth = useAuth0Auth();
  const metamaskAuth = useMetamaskAuth();

  let authMethods;

  switch (authType) {
    case 'auth0':
      authMethods = {
        login: auth0Auth.login,
        logout: auth0Auth.logout
      };
      break;
    case 'metamask':
      authMethods = {
        login: metamaskAuth.loginOrRegister,
        setWeb3: () => {}, // You might want to handle this differently.
        setWalletDetails: () => {}, // You might want to handle this differently.
      };
      break;
    default:
      throw new Error(`Unsupported authType: ${authType}`);
  }

  return {
    ...authMethods,
    isAuthenticated,
    userId,
    authSuccess: (payload) => dispatch(authSuccess(payload)),
    authError: (error) => dispatch(authError(error))
  };
};

export default useAuth;
