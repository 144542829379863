import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { TokenContext } from '../../contexts/TokenContext';

const BillIntro = () => {
  const { next } = useContext(TokenContext);
  
  return (
    <div className="bill-form">
      <h1 className="form-title">Welcome to Bill's Token Factory</h1>

      <p className="bill-label">
        This is Bill. 
      </p>
      <p className="bill-label">
        Bill love creating tokens. 
      </p>
      <p className="bill-label">
        Bill uses this factory to create tokens without writing a single line of code.
      </p>
      <p className="bill-label">
        Bill is smart.
      </p>
      <p className="bill-label">
        Be like Bill.
      </p>
      
      <Button className="bill-button" onClick={next}>Let's do this!</Button>
    </div>
  );
};

export default BillIntro;
