// client/src/api/tokenHandler.js

import jwtDecode from 'jwt-decode';

export const getToken = () => {
  const token = JSON.parse(localStorage.getItem('rememberMe')) ? 
    localStorage.getItem('token') : 
    sessionStorage.getItem('token');
  return token;
}

export const setToken = (token) => {
  JSON.parse(localStorage.getItem('rememberMe')) ? 
    localStorage.setItem('token', token) : 
    sessionStorage.setItem('token', token);
}

export const removeToken = () => {
  localStorage.removeItem('token');
  sessionStorage.removeItem('token');
}

export const getRefreshToken = () => {
  const refreshToken = JSON.parse(localStorage.getItem('rememberMe')) ? 
    localStorage.getItem('refreshToken') : 
    sessionStorage.getItem('refreshToken');
  return refreshToken;
}

export const setRefreshToken = (refreshToken) => {
  JSON.parse(localStorage.getItem('rememberMe')) ? 
    localStorage.setItem('refreshToken', refreshToken) : 
    sessionStorage.setItem('refreshToken', refreshToken);
}

export const removeRefreshToken = () => {
  localStorage.removeItem('refreshToken');
  sessionStorage.removeItem('refreshToken');
}

export const isTokenExpired = () => {
  const token = getToken();
  if (!token) return true;
  
  const jwtToken = jwtDecode(token);
  return Date.now() >= (jwtToken.exp * 1000) - 30000;
}
