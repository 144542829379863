// client/src/hooks/useWeb3Details.js

import { useState, useCallback } from 'react';
import Web3 from 'web3';
import { notification } from 'antd';

const ERRORS = {
    NON_ETHEREUM_BROWSER: 'Non-Ethereum browser detected. You should consider trying MetaMask!',
    NO_ETHEREUM_ACCOUNTS: 'No Ethereum accounts found. Please create an account in MetaMask.'
};

const messageToSign = nonce => `
0x------This is Bill------x0
░░░░░░＿*＿░░░░░░
░░░░░░⎜＿＿⎜░░░░░
░░░░░╱░•░•░⧹░░░░░
░░░░░╲＿◡＿╱░░░░░
░░░░░░░░⎜░░░░░░░░
░░░░░░╲░⎜░╱░░░░░░
░░░░░░░╲⎜╱░░░░░░░
░░░░░░░░⎜░░░░░░░░
░░░░░░░╱░╲░░░░░░░
░░░░░░╱░░░╲░░░░░░
░░░░░╱░░░░░╲░░░░░
Bill knows that security is cool.
That's why he signs with his magical nonce: ${nonce}
Bill is smart
Be Like Bill`;

const openNotification = description => {
    notification.warning({
        message: 'Warning',
        description
    });
};

const useWeb3Details = () => {
    const [state, setState] = useState({
        web3: null,
        currentAccount: null,
        balanceWei: null,
        network: null,
        signature: null,
        nonce: null,
        error: null
    });

    const [metamaskError, setMetamaskError] = useState(null);

    const handleMetamaskError = (error) => {
        switch (error.message) {
        case ERRORS.NON_ETHEREUM_BROWSER:
            setMetamaskError('You need to install MetaMask to use this application.');
            break;
        case ERRORS.NO_ETHEREUM_ACCOUNTS:
            setMetamaskError('Please create an Ethereum account in MetaMask.');
            break;
        default:
            setMetamaskError('An unknown error occurred. Please try again.');
        }
    };

    const updateState = updates => setState(prevState => ({ ...prevState, ...updates }));

    const signMessage = useCallback(async (web3Instance, message, account) => {
        return web3Instance.eth.personal.sign(message, account);
    }, []);

    const setAccountDetails = useCallback(async (web3Instance, account, nonce) => {
        const balance = await web3Instance.eth.getBalance(account);
        const networkId = await web3Instance.eth.net.getId();
        const message = messageToSign(nonce);
        const signature = await signMessage(web3Instance, message, account);
        updateState({ balanceWei: balance, network: networkId, signature });
        return { currentAccount: account, signature, web3: web3Instance };
    }, [signMessage]);

    const getAccountDetails = useCallback(async () => {
        if (!window.ethereum && !window.web3) {
          openNotification(ERRORS.NON_ETHEREUM_BROWSER);
          throw new Error(ERRORS.NON_ETHEREUM_BROWSER);
        }
        
        const provider = window.ethereum || window.web3.currentProvider;
        const web3Instance = new Web3(provider);
      
        const accounts = await web3Instance.eth.getAccounts();
        
        if (!accounts.length) {
            // Request the user to connect their account if no accounts are detected
            accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        
            // If there's still no account after the request, throw an error
            if (!accounts.length) {
                openNotification(ERRORS.NO_ETHEREUM_ACCOUNTS);
                throw new Error(ERRORS.NO_ETHEREUM_ACCOUNTS);
            }
        }
        
        const [network, balanceWei] = await Promise.all([
          web3Instance.eth.net.getId(),
          web3Instance.eth.getBalance(accounts[0])
        ]);
      
        return { web3: web3Instance, metamaskAddress: accounts[0], network, balanceWei };
      }, []);
      

    const initializeWeb3Details = useCallback(async nonce => {
        try {
            const accountDetails = await getAccountDetails();
            const { web3, metamaskAddress } = accountDetails;
            updateState({ web3, currentAccount: metamaskAddress });
            return setAccountDetails(web3, metamaskAddress, nonce);
        } catch (error) {
            console.error('Error in Ethereum connection or account details fetching', error);
            handleMetamaskError(error);
            updateState({ error: error.message });
        }
    }, [getAccountDetails, setAccountDetails]);

    return { ...state, getAccountDetails, initializeWeb3Details };
};

export default useWeb3Details;
