// client/src/auth/AuthProvider.js

import React, { useEffect, useMemo, useState, useCallback } from 'react';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import useAuth from './useAuth';
import authService from '../api/authService';
import { getToken, isTokenExpired } from '../api/tokenHandler';
import useMetamaskAuth from './useMetamaskAuth';
import { useSelector, useDispatch } from 'react-redux';
import { setAuth, clearAuth } from '../reducers/authReducer';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children, authType = 'metamask' }) => {
  const dispatch = useDispatch();
  const { getAccountDetails, logout: metamaskLogout, loginOrRegister: metamaskLogin, ...authState } = useMetamaskAuth();
  const auth = useAuth(authType);
  
  const [loading, setLoading] = useState(true);
  const [accountDetails, setAccountDetails] = useState(null);
  const [error, setError] = useState(null);

  const userId = useSelector(state => state.auth.userId);
  
  useEffect(() => {
    (async () => {
      if (authType === 'auth0') {
        const token = getToken();
        if (token && !isTokenExpired()) {
          try {
            const decoded = jwtDecode(token);
            dispatch(setAuth(decoded._id));
          } catch (error) {
            console.error('Error decoding the token:', error);
          }
        }
      } else if (authType === 'metamask' && authState.isAuthenticated) {
        const details = await getAccountDetails();
        setAccountDetails(details);
      }
      setLoading(false);
    })();
  }, [authType, authState.isAuthenticated, getAccountDetails, dispatch]);

  const handleAccountsChanged = useCallback(async (accounts) => {

    // Re-fetch or re-check the authentication state
    const token = getToken();
    if (token && !isTokenExpired() && accounts.length > 0) {
      try {
        const details = await getAccountDetails();
        setAccountDetails(details);
        if (userId) {
          authService.addMetamaskAddress(userId, details.metamaskAddress, details.network);
        } else {
          console.warn('User ID is not available to add Metamask address.');
        }
      } catch (error) {
        console.error('Error getting account details:', error);
        setError('Error getting account details. Please try again.');
      }
    }
  }, [getAccountDetails, userId]);

  useEffect(() => {
    const ethereum = window.ethereum;
    const debouncedHandleAccountsChanged = _.debounce(handleAccountsChanged, 300);
  
    if (ethereum) {
      ethereum.on("accountsChanged", debouncedHandleAccountsChanged);
    }
  
    return () => {
      if (ethereum) {
        ethereum.removeListener("accountsChanged", debouncedHandleAccountsChanged);
      }
    };
  }, [handleAccountsChanged]);

  const contextValue = useMemo(() => ({
    isLoading: loading,
    accountDetails,
    setAccountDetails,
    userId,
    error,
    setError,
    ...auth,
    ...authState,
  }), [loading, accountDetails, userId, error, auth, authState]);

  useEffect(() => {
    console.log("[AuthProvider] contextValue changed:", contextValue);
  }, [contextValue]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};
