/// client/src/api/authService.js

import axios from 'axios';
import { 
  getToken,
  getRefreshToken,
  setToken,
  setRefreshToken,
  removeToken,
  removeRefreshToken,
  isTokenExpired 
} from './tokenHandler.js';



const API_URL = `${process.env.REACT_APP_API_BASE_URL || 'http://localhost:4000'}/auth/`;

const authAxiosWithInterceptor = axios.create({
  baseURL: API_URL,
});

const authAxiosWithoutInterceptor = axios.create({
  baseURL: API_URL,
});


const refreshTokenIfNeeded = async (config) => {
  console.log('Refreshing token if needed...');
  const token = getToken();
  const refreshToken = getRefreshToken();

  if (!token || !refreshToken) {
    console.error('No token or refresh token available');
    return config;
  }

  if (isTokenExpired()) {
    console.log('Token is expired, refreshing...');
    const { newToken } = await refreshAuthToken();
    if (newToken) {
      config.headers['Authorization'] = `Bearer ${newToken}`;
    }
  } else {
    console.log('Token is not expired.');
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  
  console.log('Updated config:', config);
  return config;
}

authAxiosWithInterceptor.interceptors.request.use(refreshTokenIfNeeded);
authAxiosWithInterceptor.interceptors.response.use(undefined, async error => {
  if (
    error.config &&
    error.response &&
    error.response.status === 401 &&
    !error.config._retry
  ) {
    console.log('Received 401 error, refreshing token...');
    error.config._retry = true; // setting a flag to ensure this code block is only executed once.
    try {
      const config = await refreshTokenIfNeeded(error.config);
      console.log('Retrying request...');
      return authAxiosWithoutInterceptor(config); // Use the instance without response interceptor
    } catch (refreshError) {
      console.error('Error refreshing token:', refreshError);
      removeToken();
      removeRefreshToken();
      throw refreshError;
    }
  }
  console.error('Received error:', error);
  throw error;
});



const refreshAuthToken = async () => {
  console.log('Refreshing token...');
  try {
    const response = await authAxiosWithoutInterceptor.post(`${API_URL}refreshToken`, {
      token: getRefreshToken(),
    });
    console.log('Refreshed token:', response.data);

    if (response.data && response.data.accessToken && response.data.refreshToken) {
      const newToken = response.data.accessToken;
      const newRefreshToken = response.data.refreshToken;
      setToken(newToken);
      setRefreshToken(newRefreshToken);
      return { newToken };
    }

    return {};
  } catch (err) {
    console.error('Error refreshing token:', err);
    throw err;
  }
};


const fetchUserInfo = async (userId) => {
  console.log('Fetching user info for:', userId);
  try {
    const response = await authAxiosWithInterceptor.get(`${API_URL}getUserInfo`, { params: { userId } });
    console.log('Fetched user info:', response.data);
    if (response.data.success) {
      return response.data.user;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error('Error fetching user info:', error);
    throw error;
  }
};

const registerAuth0User = async (user) => {
  try {
    const response = await authAxiosWithInterceptor.post(`${API_URL}registerAuth0User`, user);
    if (response.data.success) {
      console.log('User registered successfully:', response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
};


const register = async (username, email, password) => {
  console.log('Registering user:', username);
  try {
    const response = await authAxiosWithoutInterceptor.post(`${API_URL}register`, {
      username,
      email,
      password,
    });
    console.log('Registered user:', response.data);
    setToken(response.data.accessToken);
    setRefreshToken(response.data.refreshToken);
    return response;
  } catch (err) {
    console.error('Error registering user:', err);
    throw err;
  }
};

const login = async (username, password) => {
  console.log('Logging in user:', username);
  try {
    const response = await authAxiosWithoutInterceptor.post(`${API_URL}login`, {
      username,
      password,
    });
    console.log('Logged in user:', response.data);
    setToken(response.data.accessToken);
    setRefreshToken(response.data.refreshToken);
    return response;
  } catch (err) {
    console.error('Error logging in user:', err);
    throw err;
  }
};

const logout = async () => {
  console.log('Logging out user...');
  try {
    // Get the token from local storage or any other storage mechanism.
    const token = getToken();
    console.log('token',token)
    // Make request to server to logout, and include the token in the Authorization header.
    const response = await authAxiosWithInterceptor.post(`${API_URL}logout`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Remove tokens from client storage
    removeToken();
    removeRefreshToken();

    console.log('Logged out user:', response);
    return response;
  } catch (err) {
    console.error('Error logging out user:', err);
    throw err;
  }
};




const rememberMe = async (remember) => {
  console.log('Remember me setting:', remember);
  localStorage.setItem('rememberMe', remember);
};

const verifyEmail = async (userId, code) => {
  console.log('Verifying email for:', userId);
  try {
    const response = await authAxiosWithoutInterceptor.post(`${API_URL}verify-email`, {
      userId,
      code,
    });
    console.log('Email verified:', response.data);
    return response;
  } catch (err) {
    console.error('Error verifying email:', err);
    throw err;
  }
};

const resetPassword = async (userId, newPassword) => {
  console.log('Resetting password for:', userId);
  try {
    const response = await authAxiosWithoutInterceptor.post(`${API_URL}reset-password`, {
      userId,
      password: newPassword,
    });
    console.log('Password reset:', response.data);
    return response;
  } catch (err) {
    console.error('Error resetting password:', err);
    throw err;
  }
};

const changePassword = async (userId, oldPassword, newPassword) => {
  console.log('Changing password for:', userId);
  try {
    const response = await authAxiosWithInterceptor.post(`${API_URL}change-password`, {
      userId,
      oldPassword,
      newPassword,
    });
    console.log('Password changed:', response.data);
    return response;
  } catch (err) {
    console.error('Error changing password:', err);
    throw err;
  }
};

const socialLogin = async (provider, accessToken) => {
  console.log('Logging in with social account:', provider);
  try {
    const response = await authAxiosWithoutInterceptor.post(`${API_URL}social-login`, {
      provider,
      accessToken,
    });
    console.log('Social login response:', response.data);
    return response;
  } catch (err) {
    console.error('Error during social login:', err);
    throw err;
  }
};

const addMetamaskAddress = async (userId, metamaskAddress, network) => {
  console.log('Adding Metamask address for:', userId);
  try {
    const response = await authAxiosWithInterceptor.post(`${API_URL}addMetamaskAddress`, {
      userId,
      metamaskAddress,
      network,
    });
    console.log('Metamask address added:', response.data);
    return response;
  } catch (err) {
    console.error('Error adding Metamask address:', err);
    throw err;
  }
};

const registerWithMetamask = async (metamaskAddress, signature, network) => {
  console.log("Registering with Metamask:", metamaskAddress);
  console.log("Registering in with signature:", signature);
  console.log("Registering in with network:", network);

  try {
    const response = await authAxiosWithoutInterceptor.post(`${API_URL}registerMetamask`, {
      metamaskAddress,
      signature,
      network,
    });
    console.log('Registered with Metamask:', response.data);
    setToken(response.data.accessToken);
    setRefreshToken(response.data.refreshToken);
    return response;
  } catch (err) {
    console.error('Error registering with Metamask:', err);
    throw err;
  } 
};

const loginWithMetamask = async (metamaskAddress, signature) => {

  try {
    const response = await authAxiosWithoutInterceptor.post(`${API_URL}loginMetamask`, {
      metamaskAddress,
      signature,
    });
    console.log('Logged in with Metamask:', response.data);
    setToken(response.data.accessToken);
    setRefreshToken(response.data.refreshToken);
    return response;
  } catch (err) {
    console.error('Error logging in with Metamask:', err);
    console.error({
      timestamp: new Date().toISOString(),
      level: 'ERROR',
      message: 'Error during loginWithMetamask',
      module: 'authService',
      error: err.message,
      stack: err.stack,
      response: err.response,
      metamaskAddress,
      signature,
    });
    throw err;
  } 
};



const generateNonce = async (metamaskAddress, network) => {
  console.log("Generating nonce for Metamask address:", metamaskAddress);
  
  try {
    const response = await authAxiosWithoutInterceptor.post(`${API_URL}generateNonce`, {
      metamaskAddress,
    });
    console.log('Nonce received:', response.data.nonce);
    return response.data.nonce;
  } catch (err) {
    console.error('Error Generating nonce:', err);
    throw err;
  } 
};

  
export default {
  generateNonce,
  fetchUserInfo,
  register,
  login,
  logout,
  refreshAuthToken,
  rememberMe,
  verifyEmail,
  resetPassword,
  changePassword,
  socialLogin,
  addMetamaskAddress,
  registerWithMetamask,
  loginWithMetamask,
  };