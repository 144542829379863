// src/auth/AuthContext.js

import { createContext } from "react";

const defaultContext = {
  isAuthenticated: false,
  userId: null,
  setUserId: () => {}, // Add default function for setUserId
  error: null, // Add default value for error
  setError: () => {}, // Add default function for setError
  user: null,
  isLoading: true,
  web3: null,
  accountDetails: null,
  loginOrRegister: async () => {},
  logout: async () => {},
  setWeb3: async () => {},
  setAccountDetails: async () => {},
  getAccountDetails: async () => {},
};

export const AuthContext = createContext(defaultContext);
