// App.js
import React from 'react';
import { TokenProvider } from './contexts/TokenContext';
import HomePage from './components/HomePage/HomePage';
import { AuthProvider } from "./auth/authProvider";


const App = () => {
  return (
    <AuthProvider>
      <TokenProvider>
        <HomePage />
      </TokenProvider>
    </AuthProvider>
  );
};

export default App;
