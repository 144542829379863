// client/src/auth/useAuth0Auth.js

import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import authService from "../api/authService";

const useAuth0Auth = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout: auth0Logout } = useAuth0();
  const [userId, setUserId] = useState(null);

  // Retrieve the user's ID from the backend or from the Auth0 user object.
  useEffect(() => {
    const fetchUserInfo = async () => {
      if (isAuthenticated) {
        try {
          // Validate that the user's Auth0 object contains 'sub' property which can be used as unique identifier
          const auth0UserId = user?.sub;
          if (!auth0UserId) {
            console.error("Auth0 user object does not contain 'sub' property.");
            return;
          }

          const backendUser = await authService.fetchUserInfo(auth0UserId);

          // Validate that the backend user object contains an 'id' property
          if (backendUser && backendUser.id) {
            setUserId(backendUser.id);
            console.log("User info successfully fetched from backend.");
          } else {
            console.log("User not found in backend, registering...");
            await authService.registerAuth0User(user);
            console.log("User registered successfully in backend.");

          }
        } catch (error) {
          console.error("Error fetching user info from backend:", error);
        }
      } else {
        console.log("User is not authenticated.");
      }
    };

    // Fetch user info only if user is authenticated and userId is not set
    if (isAuthenticated && !userId) {
      fetchUserInfo();
    }
  }, [isAuthenticated, user, userId]);

  // Provide a method for logging in
  const login = () => {
    console.log("Redirecting user for login.");
    loginWithRedirect();
  };

  // Provide a method for logging out
  const handleLogout = async () => {
    console.log("Logging out user.");
    try {
      await authService.logout(); // logout from the backend
      auth0Logout({ returnTo: window.location.origin }); // logout from Auth0
      console.log("User logged out successfully.");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return {
    isAuthenticated,
    isLoading,
    user,
    userId,
    login,
    logout: handleLogout,
  };
};

export default useAuth0Auth;
