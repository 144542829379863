// src/reducers/authReducer.js

import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
  isAuthenticated: false,
  user: null,
  userId: null,
  isLoading: false,
  accountDetails: null,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    authLoading: (state) => {
      state.isLoading = true;
    },
    authSuccess: (state, action) => {
      state.user = action.payload.userId;
      state.userId = action.payload.userId;
      state.isAuthenticated = true;
      state.isLoading = false;
      state.accountDetails = action.payload.accountDetails;
      state.error = null;
    },
    authError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    },
    setAccountDetails: (state, action) => {
      state.accountDetails = action.payload.accountDetails;
    },
    setAuth: (state, action) => {
        state.isAuthenticated = true;
        state.userId = action.payload.userId;      
      // You can add more properties here if you need to update them
    },
    clearAuth: (state) => {
      // Reset state to initialAuthState
      return initialAuthState;
    },
    logout: () => initialAuthState,
  },
});

export const {
  authLoading,
  authSuccess,
  authError,
  setAccountDetails,
  logout,
  setAuth,
  clearAuth,
} = authSlice.actions;

export default authSlice.reducer;
