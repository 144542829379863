// client/src/auth/useMetamaskAuth.js

import { useState, useContext, useEffect, useCallback } from "react";
import useWeb3Details from "../hooks/useWeb3Details";
import authService from "../api/authService";
import { useDispatch, useSelector } from 'react-redux';
import { authSuccess, logout } from '../reducers/authReducer';

export default function useMetamaskAuth() {
    const dispatch = useDispatch();
    const authState = useSelector(state => state.auth); // grab the state from our Redux store
    const [error, setError] = useState(null); // Add this line to manage error state

    const { web3, initializeWeb3Details, getAccountDetails, metamaskError } = useWeb3Details();

    useEffect(() => {
        console.log("[useMetamaskAuth] authState changed:", authState);
    }, [authState]);

    const loginOrRegister = useCallback(async () => {
        if (authState.isAuthenticated) {
            console.warn('Already authenticated, no action taken.');
            return;
        }
    
        try {
            let metamaskAddress;
            try {
                const accountDetails = await getAccountDetails();
                metamaskAddress = accountDetails.metamaskAddress;
            } catch (error) {
                console.error('Failed to get account details', error);
                setError('No Ethereum accounts found. Please create an account in MetaMask.');
                return;
            }
    
            if (!metamaskAddress) {
                setError('No Ethereum accounts found. Please create an account in MetaMask.');
                return;
            }
    
            const nonce = await authService.generateNonce(metamaskAddress);
            const { currentAccount, signature } = await initializeWeb3Details(nonce);
    
            // Validate currentAccount and signature
            if (!currentAccount || !signature) {
                throw new Error('currentAccount or signature is not present');
            }
    
            // Attempt to login
            try {
                const response = await authService.loginWithMetamask(currentAccount, signature);
                dispatch(authSuccess({ userId: response.data.userId, accountDetails: response.data.accountDetails }));
            } catch (error) {
                // If user is not found, attempt to register
                if (error.response && error.response.data.error === 'User not found with provided metamask address') {
                    const response = await authService.registerWithMetamask(currentAccount, signature, metamaskAddress);
                    dispatch(authSuccess({ userId: response.data.userId, accountDetails: response.data.accountDetails }));
                } else {
                    throw error;
                }
            }
        } catch (error) {
            console.error(`[loginOrRegister] Error: ${error.message}`);
            // Display error to the user
            setError(error.message);
        }
    }, [authState, getAccountDetails, initializeWeb3Details, dispatch]);
    


    const logout = useCallback(() => {
        dispatch(logout());
        authService.logout();
    }, [dispatch]);

    return {
        ...authState,
        web3,
        loginOrRegister,
        logout,
        getAccountDetails,
        error,
        metamaskError
    };
}